<template>
  <aheader/>
  <section class="section">
    <div class="columns is-centered">
      <div class="column is-narrow">
        <table class="table is-hoverable is-fullwidth">
          <thead>
            <td class="mobile-fontsize">
              画像
            </td>
            <td class="has-text-left ml-4 mobile-fontsize">
              商品名
            </td>
            <td class="mobile-fontsize">
              価格
            </td>
            <td class="mobile-fontsize">
              サイズ
            </td>
            <td>

            </td>
          </thead>
          <tbody>
            <tr v-for="item, index in items" :key="index">
              <!--<td class="error-message notification is-danger is-light" v-show="errorMsgList[index] != ''" v-html="errorMsgList[index]"></td>-->
              <td>
                <div class="td-image image">
                  <template v-if="item.icon.slice(-3) != 'svg'">
                    <img class="is-clickable td-image-h" :src="item.icon" @click="modalOn(index)"/>
                  </template>
                  <template v-else>
                    <img class="td-image-h" :src="item.icon"/>
                  </template>
                </div>
              </td>
              <td class="title is-6 has-text-left td-middle mobile-fontsize">
                {{ item.name }}
              </td>
              <td class="has-text-right has-text-size-4 td-middle mobile-fontsize">
                <template v-if="itemValue(index) != undefined ">
                  {{ itemValue(index) }}円
                </template>
                <template v-else>
                  -円
                </template>
              </td>
              <td class="td-middle mobile-fontsize">
                <template v-if="Object.keys(item.kindsList).length == 1">
                  なし
                </template>
                <template v-else>
                  <div :class="selectClass">
                    <select class="mobile-select" v-model="kinds[index]">
                      <option value='' disabled style='display:none;'>種類</option>
                      <option v-for="value, key in item.kindsList" :key="key">{{key}}</option>
                    </select>
                  </div>
                </template>
              </td>
              <td class="td-middle">
                <div>
                  <span :class="iconClass" @click="addtoCart(index)">
                    <i :class="faClass"></i>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
  <div class="modal" v-bind:class="modalClass">
    <div class="modal-background" @click="modalOff"></div>
      <div class="modal-content">
        <div class="box">
          <div v-if="imgPath != ''">
            <img class="big-image" :src="imgPath"/>
          </div>
          <p class="title is-5">
            {{ imgName }}
          </p>
        </div>
      </div>
    <button class="modal-close is-large" aria-label="close" @click="modalOff"></button>
  </div>
  <afooter/>
</template>
<script>
import {
  collection,
  getDocs,
  orderBy,
  query
} from "firebase/firestore";

export default {
  data() {
    return {
      items:[],
      kinds: {},
      valueList: [],
      errorMsgList:[],
      modalClass:"",
      imgName:"",
      imgPath:"",
      test:[],
      size:["S", "M", "ML", "L", "LM", "LL"],
      result:[],
      selectClass:"select td-select",
      iconClass:"icon cart-icon",
      faClass:"fa-solid fa-cart-shopping fa-lg"
    }
  },
  created() {
    this.get_items()
    this.isLogin()
  },
  mounted() {
    this.scrollTop();
    this.isMobile();
  },
  computed: {
  },
  methods: {
    pushPage(page) {
      this.$router.push(page);
    },
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    itemValue(index) {
      const kind = this.kinds[index]
      if (Object.keys(this.items[index].kindsList).length == 1) {
        this.valueList[index] = this.items[index].kindsList["one"]
      } else {
        this.valueList[index]= this.items[index].kindsList[kind]
      }
      //console.log(this.valueList)
      if (this.valueList[index] != undefined) {
        return this.valueList[index].toString().replace(/\B(?=(\d{3})+$)/g, ',');
      }
    },
    async get_items() {
      //test
      const q = query(
        collection(this.$firestore_db, 'items'),
        orderBy("#")
      );
      const qs = await getDocs(q);
      //this.items = qs.docs.map(doc => doc.data());
      this.items = qs.docs.map((doc) => {
        let item = doc.data()
        item.item_id = doc.id
        if (item.icon == true) {
          item.icon = require(`@/assets/${doc.id}.jpg`)
        } else {
          item.icon = require("@/assets/no_img.svg")
        }
        const keys = Object.keys(item.kindsList)
        if (keys.length > 1) {
          const result = this.size.filter(a => keys.indexOf(a) != -1);
          if (result.length > 0) {
            let kList = {}
            for (let i=0; i<result.length; i++) {
              kList[result[i]] = item.kindsList[result[i]]
            }
            item.kindsList = kList
          }
        }
        return item
      })
      this.makeLists()
      //console.log(this.items)
    },
    addtoCart(index) {
      this.errorMsgList[index] = ""
      if (this.kinds[index] == "") {
        this.errorMsgList[index] = "種類を選んでください"
        alert(this.errorMsgList[index])
      }
      if (this.errorMsgList[index] == "") {
        const itemKind = this.kinds[index]
        const Item = this.items[index]
        Item.kind = itemKind
        Item.value = this.valueList[index]
        this.$store.commit("addtoCart", Item)
      }
    },
    makeLists() {
      let range = this.items.length
      for (let i=0; i<range; i++) {
        if (Object.keys(this.items[i].kindsList).length == 1) {
          this.kinds[i] = "one"
          this.valueList.push(this.items[i].kindsList["one"])
        } else {
          this.kinds[i] = ""
          this.valueList.push("")
        }
        this.errorMsgList.push("")
      }
      //console.log(this.valueList)
    },
    modalOn(index) {
      this.imgName = this.items[index].name
      this.imgPath = this.items[index].icon
      this.modalClass = "is-active"
    },
    modalOff() {
      this.modalClass = ""
    },
    isLogin() {
      if (this.$store.state.account_info.dFlg == false) {
        console.log("path out")
        this.$router.push("/")
      }
    },
    isMobile() {
      if (window.matchMedia( "(max-width: 768px)" ).matches) {
        this.selectClass = "td-select"
        this.iconClass= "icon cart-icon is-small"
        this.faClass = "fa-solid fa-cart-shopping"
      } else {
        this.selectClass = "select td-select"
        this.iconClass = "icon cart-icon"
        this.faClass = "fa-solid fa-cart-shopping fa-lg "
      }
    }
  }
}
</script>
<style scoped>
.cart-icon {
  cursor: pointer !important;
}

.cart-icon :hover {
  opacity: 0.8;
}

.td-image {
  width: 10vw;
  vertical-align: middle;
}

.td-image-h {
  width:auto;
  max-height:15vh;
  margin-left: auto;
  margin-right: auto;
}

.image-size {
  height: 10vh;
}

.big-image {
  max-height:80vh;
}

.td-middle {
  vertical-align:middle;
}

@media screen and (max-width: 768px) {
  .td-select {
    width: 50px;
  }
  .mobile-select {
    color: #4a4a4a;
    border-color: gray;
    border-radius: 8%;
  }
  .mobile-fontsize {
    font-size: 13px;
  }
}

</style>
